import PropTypes from "prop-types";
import Layout from "@layout";
import PageBreadcrumb from "@components/pagebreadcrumb";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Container, Col } from "react-bootstrap";
import PostAuthorBox from "../../components/post-author";
import LargeSinglePosts from "../../components/large-single-post";
import { BlogDetailsArea, PostTitleArea, PostTitle } from "./style";

const AuthorPosts = ({ data, location, pageContext }) => {
    const new_Data = data.allStrapiArticle.nodes;
    return (
        <Layout>
            <PageBreadcrumb pageContext={pageContext} location={location} />
            <BlogDetailsArea>
                <Container>
                    <Row>
                        <Col>
                            <PostTitleArea>
                                <PostTitle>{`Author: ${new_Data[0].author.name}`}</PostTitle>
                            </PostTitleArea>
                        </Col>
                    </Row>
                    <Row className="gx-5">
                        <Col lg={8}>
                            <Row>
                                {new_Data &&
                                    new_Data.map((item, i) => {
                                        return (
                                            <Col lg={12} key={i}>
                                                <LargeSinglePosts
                                                    title={item.title}
                                                    thume_image={
                                                        item.image.localFile
                                                    }
                                                    categories={
                                                        item.category.slug
                                                    }
                                                    body={item.description}
                                                    date={item.updated_at}
                                                    slug={item.slug}
                                                    authorSlug={
                                                        item.author.slug
                                                    }
                                                    authorslug={
                                                        item.author.slug
                                                    }
                                                    dateSlug={item.updated_at}
                                                />
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <div className="blog-details-sidebar">
                                {new_Data && (
                                    <PostAuthorBox
                                        postAuthorName={new_Data[0].author.name}
                                        postAuthorImage={
                                            new_Data[0].author.picture.localFile
                                        }
                                        postAuthorBio={new_Data[0].author.email}
                                        postAuthordescription={
                                            new_Data[0].author.description
                                        }
                                        authorSlug={new_Data[0].author.slug}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </BlogDetailsArea>
        </Layout>
    );
};

AuthorPosts.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export const authorQuery = graphql`
    query($slug: String) {
        allStrapiArticle(
            filter: { author: { slug: { eq: $slug } } }
            sort: { fields: [updated_at], order: DESC }
        ) {
            nodes {
                title
                slug
                description
                updated_at
                author {
                    name
                    slug
                    email
                    description
                    picture {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FIXED
                                    width: 80
                                    height: 80
                                    quality: 100
                                )
                            }
                        }
                    }
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 850
                                height: 400
                                quality: 100
                            )
                        }
                    }
                }
                category {
                    name
                    slug
                }
            }
        }
    }
`;
export default AuthorPosts;
